const colors = {
    text: '#f1f1f1',
    background: '#000000',
    fuegoRed: '#800020',
    lavendar: '#F6E8EA',
    raisinBlack: '#22181C',
    jetGrey: '#312F2F',
    rose: '#EF626C',
    navOverlayBG: '#181818'
  };

export default colors