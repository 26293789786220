const sizes = {
    mediaMinSmall: '450px',
    mediaMaxSmall: '449px',
    mediaMinMedium: '675px',
    mediaMaxMedium: '674px',
    mediaMinLarge: '900px',
    mediaMaxLarge: '899px',
    mediaMinXLarge: '1024px',
    mediaMaxXLarge: '1023px',
    mediaMinXXLarge: '1600px',
    mediaMaxXXLarge: '1600px',
  };
  
  export const device = {
    mediaMinSmall: `(min-width: ${sizes.mediaMinSmall})`,
    mediaMaxSmall: `(max-width: ${sizes.mediaMaxSmall})`,
    mediaMinMedium: `(min-width: ${sizes.mediaMinMedium})`,
    mediaMaxMedium: `(max-width: ${sizes.mediaMaxMedium})`,
    mediaMinLarge: `(min-width: ${sizes.mediaMinLarge})`,
    mediaMaxLarge: `(max-width: ${sizes.mediaMaxLarge})`,
    mediaMinXLarge: `(min-width: ${sizes.mediaMinXLarge})`,
    mediaMaxXLarge: `(max-width: ${sizes.mediaMaxXLarge})`,
    mediaMinXXLarge: `(min-width: ${sizes.mediaMinXXLarge})`,
    mediaMaxXXLarge: `(max-width: ${sizes.mediaMaxXXLarge})`,
  };
  